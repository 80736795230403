<template>
    <div>
        <DatePicker
            v-model="getValue"
            @input="handleDatePicker"
            class="w-full dateInputhide"
            :class="{ 'invalid': error }"
            format="DD MMM, YYYY"
            value-type="YYYY-MM-DD"
            :clearable="false"
            :key="getFieldId + getValue"
            :placehodler="getPlaceholderVal"
            :disabled="disabled || formAlreadySubmitted"
        />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { DateTime } from "luxon";
// import { generateRandomString } from "@/utils/functions.js"
import debounce from "lodash/debounce";

export default {
    components: {
        DatePicker,
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    data() {
        return {
            key: '',
            getDate:'',
            opened: false,
            // inputMode: 'none'
        }
    },
    computed: {
        getValue: {
            get() {
                return this.fieldData.value || " ";
            },
            set(newValue) {
            //    let data = { value: newValue, field_id: this.fieldData.field_id };
            //     if (this.fieldData.multi_id) {
            //         data.multi_id = this.fieldData.multi_id;
            //         delete data.field_id
            //     }
            //     EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            },
        },
        getPlaceholderVal() {
            return this.fieldData?.placeholder || "Select Date";
        },
        getFieldId() {
            return this.fieldData.field_id || "";
        },
    },

    methods: {
        // generateRandomString,
        gdate(val,type,dateVal){
            if(!val) {
                let dateValue = DateTime.fromJSDate(new Date(this.fieldData.value)).toFormat('dd LLL, yyyy')
                this.getDate = this.fieldData.value ? dateValue : ''
            }
            this.$forceUpdate();
            // this.fieldData.value = JSON.parse(JSON.stringify(dateVal));
            if(dateVal) {
                this.fieldData.value = dateVal ? JSON.parse(JSON.stringify(dateVal)) : null;
                this.getDate= val ? JSON.parse(JSON.stringify(val)) : null;
            }
            this.$emit("handleFieldValueChnage", this.fieldData.value);
        },

        debouncedHandleDatePicker: debounce(function (val) {
          this.getValue = val;
        }, 300),
        
        handleDatePicker(val) {
          this.debouncedHandleDatePicker(val);
        },
    },
    mounted() {
        // this.key = this.generateRandomString()
        this.gdate()
    },
};
</script>
<style src="vue2-datepicker/index.css"></style>
<style lang="scss" scoped>
.w-full.mx-datepicker {
    width: 100% !important;
}
::v-deep {
    .mx-input {
        height: 40px !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border-radius: 4px !important;
        @apply border-box-stroke focus:border-blue-500;
        &:focus {
            box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
        }
        &:hover {
            box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
        }
    }
}
.invalid {
    ::v-deep {
        input {
            @apply border-red-600;
        }
    }
}
@media (max-width: 500px){
.mx-datepicker-content{
    display:none !important;
}
.dateInput {
    position: relative;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
} 
@media screen and (min-width: 500px) and (max-width: 1024px){
.mx-datepicker-content{
    display:none !important;
}
.dateInput {
    position: relative;
}
.dateInputEnd {
    position: relative;
}
.svgIconEnd {
   position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
}
</style>
